import React, { useState, useEffect } from "react";
import {
  useAccount,
  useWriteContract,
  useReadContract,
  useBalance,
  useWaitForTransactionReceipt,
} from "wagmi";
import { parseUnits, Address } from "viem";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WalletInfo from "../components/WalletInfo";

////////////////////////////////////////////////////////////////////
// ABI definitions for the main contract and ERC20 tokens
const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "hederaAccountId",
        type: "string",
      },
    ],
    name: "TokensBurned",
    type: "event",
  },
  {
    inputs: [],
    name: "ASSET",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BURN_ADDRESS",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "NBM",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "burnHistories",
    outputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "hederaAccountId",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "hederaAccountId",
        type: "string",
      },
    ],
    name: "burnTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getBurnHistory",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "string",
            name: "hederaAccountId",
            type: "string",
          },
        ],
        internalType: "struct TokenBurner.BurnInfo[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const erc20Abi = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];

// Contract and token addresses
const CONTRACT_ADDRESS = "0x0DF5C97F409147aEb0c7A958b6a3D64B67d0EaA1";
const ASSET_ADDRESS = "0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b";
const NBM_ADDRESS = "0x12Da2f2761038486271C99DA7e0FB4413e2B5E38";
const ASSET_DECIMALS = 18;
const NBM_DECIMALS = 9;

////////////////////////////////////////////////////////////////////
const UpgradeTokens: React.FC = () => {
  ////////////////////////////////////////////////////////////////////
  // Hooks for account connection and balance
  const { isConnected, address } = useAccount();
  const { data: assetBalance } = useBalance({ address, token: ASSET_ADDRESS });
  const { data: nbmBalance } = useBalance({ address, token: NBM_ADDRESS });
  ////////////////////////////////////////////////////////////////////
  // State variables
  const [tokenAddress, setTokenAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [hederaAccountId, setHederaAccountId] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isAssociated, setIsAssociated] = useState(false);
  const [isCheckingAssociation, setIsCheckingAssociation] = useState(false);
  const [assetAllowance, setAssetAllowance] = useState<bigint>(BigInt(0));
  const [nbmAllowance, setNbmAllowance] = useState<bigint>(BigInt(0));
  const [allowanceStatus, setAllowanceStatus] = useState<
    "unchecked" | "checking" | "insufficient" | "sufficient" | "approving"
  >("unchecked");
  const [approvalHash, setApprovalHash] = useState<`0x${string}` | undefined>(
    undefined
  );
  ////////////////////////////////////////////////////////////////////
  // Read contract hooks for allowances
  //@ts-ignore
  const { data: assetAllowanceData } = useReadContract({
    address: ASSET_ADDRESS as Address,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address as Address, CONTRACT_ADDRESS as Address],
  });
  ////////////////////////////////////////////////////////////////////
  //@ts-ignore
  const { data: nbmAllowanceData } = useReadContract({
    address: NBM_ADDRESS as Address,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address as Address, CONTRACT_ADDRESS as Address],
  });
  ////////////////////////////////////////////////////////////////////
  // Function to fetch and update allowances
  const fetchAllowances = () => {
    if (isConnected && address) {
      setAssetAllowance(
        BigInt((assetAllowanceData as bigint)?.toString() || "0")
      );
      setNbmAllowance(BigInt((nbmAllowanceData as bigint)?.toString() || "0"));
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Effect to fetch allowances when connected or allowance data changes
  useEffect(() => {
    fetchAllowances();
  }, [isConnected, address, assetAllowanceData, nbmAllowanceData]);
  ////////////////////////////////////////////////////////////////////

  // Effect to set initial token and amount based on balances
  useEffect(() => {
    if (assetBalance && Number(assetBalance.value) > 0) {
      setTokenAddress(ASSET_ADDRESS);
      setAmount(assetBalance.formatted);
    } else if (nbmBalance && Number(nbmBalance.value) > 0) {
      setTokenAddress(NBM_ADDRESS);
      setAmount(nbmBalance.formatted);
    } else {
      setTokenAddress("");
      setAmount("");
    }
  }, [assetBalance, nbmBalance]);
  ////////////////////////////////////////////////////////////////////
  // Handler for token selection change
  const handleTokenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedToken = e.target.value;
    setTokenAddress(selectedToken);
    if (selectedToken === ASSET_ADDRESS && assetBalance) {
      setAmount(assetBalance.formatted);
    } else if (selectedToken === NBM_ADDRESS && nbmBalance) {
      setAmount(nbmBalance.formatted);
    } else {
      setAmount("");
    }
    setAllowanceStatus("unchecked");
    setApprovalHash(undefined);
    setIsAssociated(false);
    setHederaAccountId("");
  };
  ////////////////////////////////////////////////////////////////////
  // Function to check token association with Hedera account
  const checkAssociation = async () => {
    if (!hederaAccountId) {
      setError("Please enter your Hedera Account ID.");
      return;
    }
    setIsCheckingAssociation(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://mainnet-public.mirrornode.hedera.com/api/v1/accounts/${hederaAccountId}`
      );
      const tokens = response.data.balance.tokens || [];
      const associated = tokens.some(
        (token: any) => token.token_id === "0.0.1991880"
      );
      setIsAssociated(associated);
      if (!associated) {
        setError("Token is not associated with your Hedera account.");
      } else {
        setError(null);
      }
    } catch (error) {
      console.error("Error checking association:", error);
      setError("Failed to check token association. Please try again.");
      setIsAssociated(false);
    } finally {
      setIsCheckingAssociation(false);
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Write contract hook for token upgrade
  //@ts-ignore
  const {
    data: upgradeHash,
    error: upgradeError,
    isPending: isUpgradePending,
    writeContract: upgradeContract,
  } = useWriteContract();

  // Function to handle token upgrade
  const handleUpgrade = async () => {
    if (!tokenAddress || !amount || !hederaAccountId) {
      setError("Please fill in all fields.");
      return;
    }

    setError(null);

    const decimals =
      tokenAddress === ASSET_ADDRESS ? ASSET_DECIMALS : NBM_DECIMALS;
    const amountInWei = parseUnits(amount, decimals);

    try {
      //@ts-ignore
      await upgradeContract({
        address: CONTRACT_ADDRESS,
        abi,
        functionName: "burnTokens",
        args: [tokenAddress, amountInWei, hederaAccountId],
      });
    } catch (error) {
      console.error("Error upgrading tokens:", error);
      setError("Failed to upgrade tokens. Please try again.");

      // Handle upgradeError
      if (upgradeError) {
        console.error("Upgrade error details:", upgradeError);
        setError(`Upgrade Error: ${upgradeError.message}`);
      }
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Hook to wait for upgrade transaction confirmation
  const { isLoading: isUpgradeConfirming, isSuccess: isUpgradeConfirmed } =
    useWaitForTransactionReceipt({
      hash: upgradeHash,
    });
  ////////////////////////////////////////////////////////////////////

  // Write contract hooks for token approvals
  const {
    data: approveHash,
    error: approveError,
    isPending: isApprovalPending,
    writeContract: approve,
  } = useWriteContract();

  const handleApprove = async () => {
    if (!tokenAddress || !amount) {
      setError("Please fill in all fields.");
      return;
    }

    setError(null);

    const decimals =
      tokenAddress === ASSET_ADDRESS ? ASSET_DECIMALS : NBM_DECIMALS;
    const amountInWei = parseUnits(amount, decimals);

    try {
      //@ts-ignore
      await approve({
        address: tokenAddress as Address,
        abi: erc20Abi,
        functionName: "approve",
        args: [CONTRACT_ADDRESS, amountInWei],
      });
    } catch (error) {
      console.error("Error approving allowance:", error);
      setError("Failed to approve allowance. Please try again.");

      // Handle approveError
      if (approveError) {
        console.error("Approval error details:", approveError);
        setError(`Approval Error: ${approveError.message}`);
      }
    }
  };
  ////////////////////////////////////////////////////////////////////
  // Hook to wait for approve transaction confirmation
  const { isLoading: isApprovalConfirming, isSuccess: isApprovalConfirmed } =
    useWaitForTransactionReceipt({
      hash: approveHash,
    });

  useEffect(() => {
    if (isApprovalConfirmed) {
      setAllowanceStatus("sufficient");
      fetchAllowances();
    }
  }, [isApprovalConfirmed]);
  ////////////////////////////////////////////////////////////////////
  // Function to check allowance
  const checkAllowance = async () => {
    if (!tokenAddress || !amount) return;
    setAllowanceStatus("checking");
    try {
      const decimals =
        tokenAddress === ASSET_ADDRESS ? ASSET_DECIMALS : NBM_DECIMALS;
      const requiredAmount = parseUnits(amount, decimals);

      const currentAllowance =
        tokenAddress === ASSET_ADDRESS ? assetAllowance : nbmAllowance;

      if (currentAllowance >= requiredAmount) {
        setAllowanceStatus("sufficient");
      } else {
        setAllowanceStatus("insufficient");
      }
    } catch (error) {
      console.error("Error checking allowance:", error);
      setAllowanceStatus("unchecked");
    }
  };

  // Effect to check allowance when relevant state changes
  useEffect(() => {
    checkAllowance();
  }, [assetAllowance, nbmAllowance, amount, tokenAddress]);
  ////////////////////////////////////////////////////////////////////

  if (tokenAddress) {
    <WalletInfo />;
  }
  // Render function
  if (!isConnected)
    return (
      <p className="text-center text-blue-500 m-6">
        Connect your wallet to upgrade your tokens.
      </p>
    );

  if (!tokenAddress)
    return (
      <>
        <p className="text-center text-blue-500 my-5">
          You don't have tokens to upgrade.
        </p>
      </>
    );

  if (isUpgradeConfirmed)
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-sm mx-auto">
          <h2 className="text-3xl font-bold mb-6 text-green-600">Success!</h2>
          <p className="mb-6 text-gray-700">
            Your will get your ASSET(HTS) tokens in less than 24 hours.
          </p>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
          >
            OK
          </button>
        </div>
      </div>
    );

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mt-4 max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Upgrade Your Tokens
      </h2>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      {isUpgradeConfirmed && (
        <p className="text-green-500 text-center mb-4 font-bold">
          Your tokens have been successfully upgraded!
        </p>
      )}

      {/* Display approval error */}
      {approveError && (
        <p className="text-red-500 text-center mb-4 w-full break-words">
          {`Approval Error: ${approveError.message}`}
        </p>
      )}

      {/* Display upgrade error */}
      {upgradeError && (
        <p className="text-red-500 text-center mb-4 w-full break-words">
          {`Upgrade Error: ${upgradeError.message}`}
        </p>
      )}

      <div className="space-y-4">
        <div>
          <label className="block text-gray-700 mb-2">Select Token</label>
          <select
            value={tokenAddress}
            onChange={handleTokenChange}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="" disabled>
              Select Token
            </option>
            {Number(assetBalance?.value) > 0 && (
              <option value={ASSET_ADDRESS}>ASSET</option>
            )}
            {Number(nbmBalance?.value) > 0 && (
              <option value={NBM_ADDRESS}>NBM</option>
            )}
          </select>
        </div>

        <div>
          <label className="block text-gray-700 mb-2">Amount</label>
          <input
            type="text"
            value={amount}
            readOnly
            className="w-full border border-gray-300 rounded-md p-2 bg-gray-100"
          />
        </div>

        <div>
          <h3 className="font-bold mb-2">Step 1: Associate Token</h3>
          {!isAssociated ? (
            <>
              <p className="text-gray-700 mb-2">
                Please associate ASSET TOKEN (0.0.1991880) with your Hedera
                account.
              </p>
              <input
                type="text"
                value={hederaAccountId}
                onChange={(e) => setHederaAccountId(e.target.value)}
                placeholder="Enter Hedera Account ID"
                className="w-full border border-gray-300 rounded-md p-2 mb-2"
              />
              <button
                onClick={checkAssociation}
                className={`w-full font-bold py-2 px-4 rounded ${
                  isCheckingAssociation
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
                disabled={isCheckingAssociation}
              >
                {isCheckingAssociation ? "Checking..." : "Check Association"}
              </button>
              <p className="text-center mt-2">
                Need help?{" "}
                <a
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                  href="https://medium.com/@elastum/hedera-how-to-associate-your-tokens-c7021c96cc25"
                >
                  How to Associate Tokens
                </a>
              </p>
            </>
          ) : (
            <p className="text-green-500">Token is associated!</p>
          )}
        </div>

        {isAssociated && (
          <div>
            <h3 className="font-bold mb-2">Step 2: Allowance</h3>
            {allowanceStatus === "checking" && (
              <p className="text-blue-500">Checking allowance...</p>
            )}
            {allowanceStatus === "insufficient" && (
              <p className="text-red-500 mb-2">
                Insufficient allowance. Please approve to proceed.
              </p>
            )}
            {allowanceStatus === "sufficient" && (
              <p className="text-green-500">Allowance is sufficient!</p>
            )}
            {isApprovalPending ||
              (isApprovalConfirming && (
                <p className="text-blue-500">
                  Please wait, it may take a few minutes.
                </p>
              ))}

            {allowanceStatus !== "sufficient" && (
              <button
                onClick={handleApprove}
                className={`w-full font-bold py-2 px-4 rounded ${
                  isApprovalPending || isApprovalConfirming
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-green-500 hover:bg-green-600 text-white"
                }`}
                disabled={isApprovalPending || isApprovalConfirming}
              >
                {isApprovalPending || isApprovalConfirming
                  ? "Approving..."
                  : "Approve Allowance"}
              </button>
            )}
          </div>
        )}

        {isAssociated && allowanceStatus === "sufficient" && (
          <div>
            <h3 className="font-bold mb-2">Step 3: Upgrade Tokens</h3>
            {(isUpgradePending || isUpgradeConfirming) && (
              <p className="text-blue-500">
                Please wait, it may take a few minutes.
              </p>
            )}
            <button
              onClick={handleUpgrade}
              className={`w-full font-bold py-2 px-4 rounded ${
                isUpgradePending || isUpgradeConfirming
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600 text-white"
              }`}
              disabled={isUpgradePending || isUpgradeConfirming}
            >
              {isUpgradePending || isUpgradeConfirming
                ? "Upgrading..."
                : "Upgrade Tokens"}
            </button>
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default UpgradeTokens;
