import React from "react";
import ConnectButton from "../components/ConnectButton";
import Footer from "../sections/Footer";
import UpgradeTokens from "../components/UpgradeTokens";
import { Web3ModalProvider } from "../components/Web3ModalProvider";
import TokensInfo from "../components/TokensInfo"; // Import TokensInfo
import TransactionList from "../components/TransactionList";
const ConvertPage: React.FC = () => {
  return (
    <div className="">
      <Web3ModalProvider>
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
              <div className="max-w-md mx-auto">
                <div className="divide-y divide-gray-200">
                  <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <TokensInfo /> {/* Add TokensInfo component here */}
                  </div>
                  <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                    <div className="text-center mb-5">
                      <ConnectButton />
                    </div>
                    <UpgradeTokens />
                  </div>
                  <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                    <div className="text-center mb-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TransactionList />
      </Web3ModalProvider>
      <Footer />
    </div>
  );
};

export default ConvertPage;
