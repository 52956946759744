import React from "react";
import { Link } from "react-router-dom";


const Introducing: React.FC = () => {
  return (
    <>

      <header
        id="introducing"
        className="relative p-12 text-gray-900 text-center bg-cover bg-center"
        style={{ backgroundImage: "url('/dust.jpg')" }}
      >
        {/* Background overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Content */}
        <div className="relative z-10">
          <h1 className="text-5xl font-bold text-gray-200 mb-12 mt-12 drop-shadow-lg">
            iAssets
          </h1>

          <p className="text-2xl text-gray-200 mb-12 drop-shadow-lg">
            iAssets is building a web3, decentralized, community-driven, and
            open-source social media platform where your voice is free!
          </p>

          <div className="flex justify-center space-x-4">
            <Link
              to="/convert"
              className="inline-flex items-center px-5 py-3 text-lg text-white bg-gray-700 rounded-full hover:bg-gray-900 transition duration-300"
            >
              Update Tokens
            </Link>
            <Link
              target="_blank"
              to="https://ibird.io"
              className="inline-flex items-center px-5 py-3 text-lg text-white bg-gray-700 rounded-full hover:bg-gray-900 transition duration-300"
            >
              Join iBird
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default Introducing;