import React from "react";

const Ibird: React.FC = () => (
  <div
    className="w-full p-6 sm:p-10 lg:p-16 xl:p-20 2xl:p-24 bg-gray-900 text-white shadow-2xl py-16"
    style={{
      backgroundImage: "url('/data.png')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
  >
    <section>
      <div className="text-center">
        <h1 className="text-5xl font-bold text-white mb-12">
          Welcome to iBird
        </h1>

        <p className="text-2xl mb-10 leading-relaxed max-w-3xl mx-auto text-white">
          iBird is a revolutionary social media platform built on Hedera by
          iAssets. It leverages Hashgraph technology for unparalleled speed,
          security, and innovation.
        </p>

        <div className="grid gap-12 lg:grid-cols-3 sm:grid-cols-1">
          <div className="bg-white shadow-xl rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300 bg-opacity-90">
            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
              Community-Driven
            </h2>
            <p className="text-gray-700 mb-4">
              Governed democratically by its users, iBird evolves with the
              community at its heart. Your voice shapes its future.
            </p>
            <a
              href="https://discord.gg/xM7SkkTEAG"
              className="text-blue-600 hover:text-blue-600 transition duration-300 mt-4 inline-block bg-gray-300 p-3 hover:bg-gray-400 rounded-xl"
            >
              Join The Community
            </a>
          </div>

          <div className="bg-white shadow-xl rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300 bg-opacity-90">
            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
              Open-Source
            </h2>
            <p className="text-gray-700 mb-4">
              With transparent, open-source code, iBird fosters trust and
              continuous innovation, free from hidden agendas.
            </p>
            <a
              href="https://github.com/iassetsorg/Project-Harriet"
              className="text-blue-600 hover:text-blue-600 transition duration-300 mt-4 inline-block bg-gray-300 p-3 hover:bg-gray-400 rounded-xl"
            >
              Start Building Freedom
            </a>
          </div>

          <div className="bg-white shadow-xl rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300 bg-opacity-90">
            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
              Hedera-Powered
            </h2>
            <p className="text-gray-700 mb-4">
              Harnessing Hedera's advanced technology, iBird offers a fast,
              secure, and equitable social media experience.
            </p>
            <a
              href="https://hedera.com/"
              className="text-blue-600 hover:text-blue-600 transition duration-300 mt-4 inline-block bg-gray-300 p-3 hover:bg-gray-400 rounded-xl"
            >
              Learn about Hedera
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Ibird;
