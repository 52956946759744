import React from "react";
import {
  FaHome,
  FaSyncAlt, // Updated icon for Update Tokens
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png"; // Adjust the path as necessary

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <nav className="bg-gray-900 text-white p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="iAssets Logo" className="h-8 w-8 mr-2" />
          </Link>
          <Link to="/" className="text-2xl font-bold">
            iAssets
          </Link>
        </div>
        <div className="flex space-x-4">
          {location.pathname === "/" && (
            <Link
              to="/convert"
              className="flex items-center hover:text-gray-300 transition-colors duration-300"
            >
              <FaSyncAlt className="mr-2" /> Update Tokens
            </Link>
          )}
          {(location.pathname === "/convert" ||
            location.pathname === "/TermsOfService") && (
            <Link
              to="/"
              className="flex items-center hover:text-gray-300 transition-colors duration-300"
            >
              <FaHome className="mr-2" /> Home
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Menu;
