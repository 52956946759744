import React, { useState } from 'react';
import { FaRocket,FaCode,FaExpandAlt, FaUsers, FaGlobe, FaUserCircle, FaImage, FaMoneyBillWave, FaTools, FaChartLine, FaMobileAlt, FaVoteYea, FaUserFriends } from 'react-icons/fa';
import { SiCreatereactapp } from "react-icons/si";
import { GiBurningEmbers,GiStumpRegrowth,GiTreeGrowth } from "react-icons/gi";
import { GrUpdate } from "react-icons/gr";


type RoadmapItem = {
  version: string;
  title: string;
  icon: JSX.Element;
  features: string[];
};

type RoadmapData = {
  iBird: RoadmapItem[];
  iAssets: RoadmapItem[];
};

const Roadmap: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'iBird' | 'iAssets'>('iBird');

  const roadmapData: RoadmapData = {
    iBird: [
      {
        version: "v0.0.1",
        title: "Threads",
        icon: <FaRocket />,
        features: [
          "🟢 Connect wallet",
          "🟢 Create threads",
          "🟢 Read thread's data",
          "🟢 Send message to threads",
        ],
      },
      {
        version: "v0.0.2",
        title: "Interactions",
        icon: <FaUsers />,
        features: [
          "🟢 Reply to messages",
          "🟢 Like",
          "🟢 Dislike",
          "🟢 Write Comments",
        ],
      },
      {
        version: "v0.0.3",
        title: "Explore Feed",
        icon: <FaGlobe />,
        features: [
          "🟢 Directory for all threads",
          "🟢 Explore Topic: 0.0.3946144",
          "🟢 Users can send their threads to the Explore Feed",
        ],
      },
      {
        version: "v0.0.4",
        title: "Profile & Planet",
        icon: <FaUserCircle />,
        features: [
          "🟢 Create user Profile NFT",
          "🟢 Create user Profile Topic",
          "🟢 Save Profile Topic in Profile NFT",
          "🟢 Create user Threads",
          "🟢 Save user Threads in Profile Topic",
          "🟢 Planet Topic: 0.0.4320596",
        ],
      },
      {
        version: "v0.0.5",
        title: "Media Support",
        icon: <FaImage />,
        features: [
          "🟢 Save media files on IPFS",
          "🟢 Send media files with messages",
        ],
      },
      {
        version: "v0.0.6",
        title: "Tipping Support",
        icon: <FaMoneyBillWave />,
        features: [
          "🟢 Tip HBAR, ASSET, USDC",
          "🟢 Tip SAUCE, GRELF, XPH",
          "🟢 Tip DOVU, SAUCEINU, JAM",
          "🟢 Tip HSUITE, BSL, BULLBAR",
          "🟢 Tip MFM, NORDOGE, KARATE",
        ],
      },
      {
        version: "v0.0.7",
        title: "UPGRADO",
        icon: <FaTools />,
        features: [
          "🟢 Hash Link #",
          "🟢 Dark & light theme",
          "🟢 Universal Topic",
          "🟢 Interactive polls",
          "🟢 Users profile",
        ],
      },
      {
        version: "v0.0.8",
        title: "Reward & Analyze",
        icon: <FaChartLine />,
        features: [
          "🟢 Reward engine",
          "🟢 System Analytics",
        ],
      },
      {
        version: "v0.0.9",
        title: "Applications",
        icon: <FaMobileAlt />,
        features: [
          "🟢 Date & Time Stamps",
          "🟢 Character Counter",
          "🟢 Link, #, $ Reader",
          "🟢 Explorer Infinite Scrolling",
          "🟠 Wallet Connecet",
          "🟠 IOS Application",
          "🟠 Android Application",
          "🟠 Desktop Application",
        ],
      },
      {
        version: "v0.1.0",
        title: "Community Governance",
        icon: <FaVoteYea />,
        features: [
          "🟠 iBird Gen 1: The First Flight, Holders propose and vote on key platform updates",
        ],
      },
      {
        version: "v0.1.1",
        title: "Followers and Channels",
        icon: <FaUserFriends />,
        features: [
          "🟠 Followers",
          "🟠 Channels",
        ],
      },
    ],
    iAssets: [
      {
        version: "Stage 1",
        title: "Creation",
        icon: <SiCreatereactapp />,
        features: [
          "🟢 Creation of NFTBlackMarket",
          "🟢 NFTBlackMarket Website Launch",
          "🟢 Creation of NBM (BSC) Token",
          "🟢 NBM Token Listed on PancakeSwap",
          "🟢 NBM Liquidity Locked Forever",
          "🟢 NBM Smartcontract Audited by TechRate",
          "🟢 Launch of NFT Marketplace",
        ],
      },
      {
        version: "Stage 2",
        title: "Rebrandibg",
        icon: <GrUpdate />,
        features: [
       
          "🟢 NFTBlackMarket Rebranded to iAssets",
          "🟢 NBM (BSC)Token Rebranded to ASSET (BSC)",
          "🟢 NFT Marketplace Rebranded as iAssets.market",
          "🟢 iAssets.org Website Launch",
          "🟢 NBM (BSC) to ASSET (BSC) Converter",
        ],
      },
      {
        version: "Stage 3",
        title: "Expansion",
        icon: <FaExpandAlt />,
        features: [
          "🟢 ASSET Token Listed on IndoEx",
          "🟢 Launch of iAssets.Social (Social Media) on BSC",
          "🟢 Launch of iAssets.Marketing",
      
        ],
      },
      {
        version: "Stage 4",
        title: "Destruction",
        icon: <GiBurningEmbers />,
        features: [
          "🟢 iAssets.Market (NFT Platform) deprecated",
          "🟢 iAssets.Marketing deprecated",
          "🟢 NBM (BSC) Token deprecated",
          "🟢 ASSET (BSC) Token deprecated",
          "🟢 iAssets.Social on BSC deprecated",

        ],
      },
      {
        version: "Stage 5",
        title: "Growth",
        icon: <GiStumpRegrowth />,
        features: [
          "🟢 Migration to Hedera",
          "🟢 Launch iAssets.Social on Hedera",
          "🟢 Creation of ASSET(HTS) Token on Hedera",
          "🟢 NBM (BSC), ASSET(BSC )to ASSET(HTS) Converter",
          "🟢 Rebrand iAssets.Social to iBird.io",
          "🟢 The project became Open-Source & Community-Driven",
          "🟢 Launch of iBird.community",
          "🟢 Redesign iAssets.org",
          "🟠 Transfer iBird.community website to iBird.io",
        ],
      },
      {
        version: "Stage 6",
        title: "Community",
        icon: <GiTreeGrowth />,
        features: [
          "🟠 100 Users",
          "🟠 1,000 Users",
          "🟠 10,000 Users",
          "🟠 100,000 Users",
          "🟠 1,000,000 Users",
          "🟠 10,000,000 Users",
          "🟠 100,000,000 Users",
          "🟠 1,000,000,000 Users",
        ],
      },
    ],
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-white py-16" style={{ backgroundImage: "url('/bokeh.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
      <div className="container mx-auto px-4">
        <h2 className="text-5xl text-center text-gray-900 mb-14">Our Journey</h2>
        <div className="flex justify-center mb-8">
          <button
            className={`px-4 py-2 mx-2 text-xl texfont-semibold ${activeTab === 'iBird' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'}`}
            onClick={() => setActiveTab('iBird')}
          >
            iBird
          </button>
          <button
            className={`px-4 py-2 mx-2 text-xl font-semibold ${activeTab === 'iAssets' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'}`}
            onClick={() => setActiveTab('iAssets')}
          >
            iAssets
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {roadmapData[activeTab].map((item, index) => (
            <div key={index} className="bg-white bg-opacity-80 p-6 rounded-lg shadow-xl transform transition duration-500 hover:scale-105">
              <div className="flex items-center mb-4">
                <div className="text-3xl text-blue-500 mr-4">
                  {item.icon}
                </div>
                <h3 className="text-2xl font-semibold text-gray-800">
                  <span className="text-blue-500">{item.version}</span> {item.title}
                </h3>
              </div>
              <ul className="text-sm text-gray-600 space-y-2">
                {item.features.map((feature, i) => (
                  <li key={i} className="flex items-center">
                    <span className={`w-5 h-5 rounded-full mr-2 flex-shrink-0 ${feature.startsWith('🟢') ? 'bg-green-500' : 'bg-yellow-500'}`}></span>
                    {feature.slice(2)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;