
import React from 'react';
import { Link, } from 'react-router-dom';

const Footer = () => {

    const handleLinkClick = (path: string) => (event: React.MouseEvent) => {
        event.preventDefault();
        window.open(path, '_blank');
    };

    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 gap-4 md:flex md:justify-between md:items-center">
                    <div className="mb-4 md:mb-0">
                        <h1 className="text-2xl font-bold">iAssets</h1>
                        <p className="text-sm">© 2024 iAssets. All rights reserved.</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 md:flex md:space-x-4">
                        <a href="https://x.com/iAssetsOrg" target="_blank" className="text-gray-400 hover:text-white">X</a>
                        <a href="https://github.com/iassetsorg" target="_blank" className="text-gray-400 hover:text-white">Github</a>
                        <a href="http://discord.gg/xM7SkkTEAG" target="_blank" className="text-gray-400 hover:text-white">Discord</a>
                        <a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x12Da2f2761038486271C99DA7e0FB4413e2B5E38" target="_blank" className="text-gray-400 hover:text-white">NBM (BSC)</a>
                        <a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x6b471d5ab9f3d92a600e7d49a0b135bf6d4c6a5b" target="_blank" className="text-gray-400 hover:text-white">ASSET (BSC)</a>
                        <a href="https://www.saucerswap.finance/swap/HBAR/0.0.1991880" target="_blank" className="text-gray-400 hover:text-white">ASSET (HTS)</a>
                        <a href="https://sentx.io/nft-marketplace/0.0.3844404" target="_blank" className="text-gray-400 hover:text-white">iBird Gen1</a>
                        <Link to="/TermsOfService" onClick={handleLinkClick('/TermsOfService')} className="text-gray-400 hover:text-white">Terms of Service</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;