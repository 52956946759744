import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAccount } from "wagmi";

interface Event {
  user: string;
  tokenAddress: string;
  token: string; // New field for token name
  hederaAccountId: string;
  amount: string;
  blockNumber: number;
  transactionHash: string;
  convert: boolean;
  convertHash: string;
}

const TransactionList: React.FC = () => {
  const { address, isConnected } = useAccount();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true; // Track if component is mounted
    let intervalId: NodeJS.Timeout;

    const fetchEvents = async () => {
      try {
        // Fetch events.json from the public directory
        const response = await axios.get<Event[]>("/events.json");
        const eventsData = response.data;

        if (isConnected && address) {
          // Filter events by connected wallet address
          const userEvents = eventsData.filter(
            (event) => event.user.toLowerCase() === address.toLowerCase()
          );
          if (isMounted) {
            setEvents(userEvents);
          }
        } else if (isMounted) {
          setEvents([]);
        }
      } catch (error) {
        console.error("Error fetching events data:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchEvents();

    // Refresh data every 5 minutes
    intervalId = setInterval(fetchEvents, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [isConnected, address]);

  if (!isConnected) {
    return (
      <p className="text-center text-blue-500 my-6">
        Connect your wallet to view your transactions.
      </p>
    );
  }

  if (loading) {
    return <p className="text-center text-gray-500">Loading transactions...</p>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mt-4 w-full">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        Your Transactions
      </h2>
      <p className="text-gray-600 mb-4">
        Note: Takes 5 minutes for the transaction to be shown here after it has
        been confirmed on BSC.
      </p>
      {/* Limit the height and enable scrolling */}
      <div className="overflow-y-auto max-h-[500px]">
        {events.length === 0 ? (
          <p className="text-center text-gray-500">
            No transactions found for your wallet.
          </p>
        ) : (
          <ul className="space-y-4">
            {events.map((event) => (
              <li
                key={event.transactionHash}
                className="p-4 bg-gray-100 rounded-lg"
              >
                {/* Display Status */}
                <div className="mb-2">
                  {event.convert ? (
                    <p className="text-green-600 font-semibold">Success</p>
                  ) : (
                    <p className="text-orange-600 font-semibold">Pending...</p>
                  )}
                </div>
                <p className="text-gray-600">
                  <span className="font-semibold">Token:</span> {event.token}
                </p>
                {/* Exchange Rate */}
                {event.token === "ASSET" && (
                  <p className="text-gray-600">
                    <span className="font-semibold">
                      1 ASSET(BSC) = 1 ASSET(HTS)
                    </span>
                  </p>
                )}
                {event.token === "NBM" && (
                  <p className="text-gray-600">
                    <span className="font-semibold">
                      1000 NBM(BSC) = 1 ASSET(HTS)
                    </span>
                  </p>
                )}
                <p className="text-gray-600">
                  <span className="font-semibold">Token Address:</span>{" "}
                  <span className="break-all">{event.tokenAddress}</span>
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">Hedera Account ID:</span>{" "}
                  {event.hederaAccountId}
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold">Amount:</span> {event.amount}
                </p>

                <p className="text-gray-600">
                  <span className="font-semibold">BSC Hash:</span>{" "}
                  <a
                    href={`https://bscscan.com/tx/${event.transactionHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline break-all"
                  >
                    {event.transactionHash}
                  </a>
                </p>
                {/* Show Convert Hash if conversion is successful */}
                {event.convert && (
                  <p className="text-gray-600">
                    <span className="font-semibold">HTS Hash:</span>{" "}
                    <a
                      href={`https://hashscan.io/mainnet/transaction/${event.convertHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline break-all"
                    >
                      {event.convertHash}
                    </a>
                  </p>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TransactionList;
