import React from "react";
import { useAccount, useBalance, useReadContract } from "wagmi";
import { formatUnits } from "viem";

const erc20ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

function formatBalance(balance: number) {
  return balance.toFixed(5).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const WalletInfo: React.FC = () => {
  const { address, isConnected } = useAccount();
  const { data: balance } = useBalance({ address });
  //@ts-ignore
  const nbmBalance = useReadContract({
    address: "0x7f686B29cc152F707cC3ec0E50659bAdbA15E4Ae",
    abi: erc20ABI,
    functionName: "balanceOf",
    args: [address],
    chainId: 97,
  });

  //@ts-ignore
  const assetBalance = useReadContract({
    address: "0x932D115cfdC5d853E9569Fa53b9cd06930F71dbB",
    abi: erc20ABI,
    functionName: "balanceOf",
    args: [address],
    chainId: 97,
  });

  if (!isConnected) return null;

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mt-4 max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
        Wallet Information
      </h2>
      <div className="space-y-4">
        <div className="p-4 bg-gray-100 rounded-lg">
          <p className="text-gray-600">
            <span className="font-semibold">Address:</span>{" "}
            <span className="font-mono break-all">{address}</span>
          </p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg">
          <p className="text-gray-600">
            <span className="font-semibold">Balance:</span>{" "}
            {balance
              ? formatBalance(
                  parseFloat(formatUnits(balance.value, balance.decimals))
                )
              : "0.000"}{" "}
            {balance?.symbol}
          </p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg">
          <p className="text-gray-600">
            <span className="font-semibold">NBM Balance:</span>{" "}
            {nbmBalance.data
              ? formatBalance(
                  parseFloat(formatUnits(nbmBalance.data as bigint, 9))
                )
              : "0.000"}{" "}
            NBM
          </p>
        </div>
        <div className="p-4 bg-gray-100 rounded-lg">
          <p className="text-gray-600">
            <span className="font-semibold">ASSET Balance:</span>{" "}
            {assetBalance.data
              ? formatBalance(
                  parseFloat(formatUnits(assetBalance.data as bigint, 18))
                )
              : "0.000"}{" "}
            ASSET
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletInfo;
