import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menu from "./components/Menu";
import Introducing from "./sections/Introducing";
import Footer from "./sections/Footer";
import ConvertPage from "./pages/convertPage";
import TermsOfService from "./pages/TermsOfService";
import Roadmap from "./sections/Roadmap";
import Ibird from "./sections/Ibird";
import HowWorks from "./sections/HowWorks";
import "./index.css";


function App() {
  return (
    <Router>
      <div className="font-roboto">
        <Menu />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Introducing />
                <Ibird />
                <HowWorks />
                <Roadmap />
                <Footer />
              </>
            }
          />
          <Route path="/convert" element={<ConvertPage />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
