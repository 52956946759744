import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { defineChain } from "viem";

//@ts-ignore
export const bscMainnet = defineChain({
  id: 56,
  name: "Binance Smart Chain",
  nativeCurrency: { name: "Binance Coin", symbol: "BNB", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://bsc-dataseed.binance.org/"] },
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
});

const projectId = "b5b3cc54be57cfebcd2d8263560b4c73";

const metadata = {
  name: "iassets",
  description: "Internet of Assets",
  url: "https://iassets.org/",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bscMainnet] as const;
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
});
