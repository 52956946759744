import React from "react";
import { GiFreedomDove } from "react-icons/gi";

const HowWorks: React.FC = () => (
  <div
    className="w-full p-6 sm:p-10 lg:p-16 xl:p-20 2xl:p-24 bg-gray-100 text-gray-900 shadow-2xl"
    style={{
      backgroundImage: "url('/technology.jpg')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
  >
    <div className="text-center mt-4">
      <h2 className="text-4xl font-bold text-white mb-16">
        How does iBird work?
      </h2>
      <div className="grid gap-12 lg:grid-cols-2 sm:grid-cols-1">
        <div className="bg-white bg-opacity-90 shadow-xl rounded-lg p-8 hover:shadow-2xl transition-shadow duration-300 text-left">
          <h3 className="text-2xl font-semibold text-blue-600 mb-4">
            ⚙️ Back-End
          </h3>
          <p className="text-gray-700 mb-4">
            The backend is Hedera Consensus Service to keep a secure and
            accurate record of all events and actions, like a diary that
            timestamps everything.
          </p>
          <p className="text-gray-700 mb-4">
            This includes writing messages, likes, comments, and other
            activities on the Hedera network, ensuring everything is transparent
            and tamper-proof.
          </p>
          <p className="text-gray-700 mb-4">
            It also uses the Hedera Token Service to create unique digital
            profiles for users in the form of NFTs (Non-Fungible Tokens), like
            digital ID cards.
          </p>
        </div>
        <div className="bg-white bg-opacity-90 shadow-xl rounded-lg p-8 hover:shadow-2xl transition-shadow duration-300 text-left">
          <h3 className="text-2xl font-semibold text-blue-600 mb-4">
            🖥️ Front-End
          </h3>
          <p className="text-gray-700 mb-4">
            The frontend is what you see and interact with on the screen. It has
            a sleek and user-friendly design to make your experience smooth and
            enjoyable.
          </p>
          <p className="text-gray-700 mb-4">
            It has smart features to read data from Hedera and write data
            efficiently, ensuring everything works seamlessly. This means you
            can interact with the platform in real-time, with all your actions
            being securely recorded on the Hedera network.
          </p>
          <p className="text-gray-700 mb-4">
            The front-end continuously reads data from Hedera network to provide
            you with the most up-to-date messages and interactions.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default HowWorks;
