import React, { useState, useEffect } from "react";
import axios from "axios";

interface TokensPrice {
  nbmPricePancake: string;
  assetPriceCMC: string;
  assetPriceSaucer: string;
  // Include other properties if necessary
}

interface BurnData {
  nbmBurnedPercentage: string;
  assetBurnedPercentage: string;
  // Include other properties if necessary
}

const TokensInfo = () => {
  const [burnData, setBurnData] = useState<BurnData | null>(null);
  const [tokensPrice, setTokensPrice] = useState<TokensPrice | null>(null);

  const ASSET_BURN_PROOF =
    "https://bscscan.com/token/0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b?a=0x000000000000000000000000000000000000dead";
  const NBM_BURN_PROOF =
    "https://bscscan.com/token/0x12da2f2761038486271c99da7e0fb4413e2b5e38?a=0x000000000000000000000000000000000000dead";

  useEffect(() => {
    const fetchBurnData = async () => {
      try {
        const response = await axios.get("/burnData.json");
        setBurnData(response.data);
      } catch (error) {
        console.error("Error fetching burn data:", error);
      }
    };

    const fetchTokensPrice = async () => {
      try {
        const response = await axios.get("/tokensPrice.json");
        setTokensPrice(response.data);
      } catch (error) {
        console.error("Error fetching tokens price:", error);
      }
    };

    fetchBurnData();
    fetchTokensPrice();

    // Optional: Refresh data every 5 minutes
    const interval = setInterval(() => {
      fetchBurnData();
      fetchTokensPrice();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-xl max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Token Information
      </h1>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Tokens Price
      </h2>
      <div className="flex flex-col space-y-6 mb-6">
        <div className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-gray-800">
            NBM (BSC):{" "}
            <span className="text-md text-gray-600">
              ${tokensPrice?.nbmPricePancake}
            </span>
          </h3>
          <div className="mt-2 text-sm text-gray-600">
            <p>1000 NBM = 1 ASSET(HTS)</p>
          </div>
        </div>
        <div className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-gray-800">
            ASSET (BSC):{" "}
            <span className="text-md text-gray-600">
              ${tokensPrice?.assetPriceCMC}
            </span>
          </h3>
          <div className="mt-2 text-sm text-gray-600">
            <p> 1 ASSET(BSC) = 1 ASSET(HTS)</p>
          </div>
        </div>
        <div className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-gray-800">
            ASSET (HTS):{" "}
            <span className="text-md text-gray-600">
              ${tokensPrice?.assetPriceSaucer}
            </span>
          </h3>
        </div>
      </div>

      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Converted Tokens
      </h2>
      <div className="flex flex-col space-y-6">
        <div className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-gray-800">
            NBM (BSC):{" "}
            <a
              href={NBM_BURN_PROOF}
              target="_blank"
              rel="noopener noreferrer"
              className="text-md text-blue-500 hover:text-blue-700 hover:underline"
            >
              {burnData?.nbmBurnedPercentage}%
            </a>
          </h3>
        </div>
        <div className="flex-1 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-gray-800">
            ASSET (BSC):{" "}
            <a
              href={ASSET_BURN_PROOF}
              target="_blank"
              rel="noopener noreferrer"
              className="text-md text-blue-500 hover:text-blue-700 hover:underline"
            >
              {burnData?.assetBurnedPercentage}%
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default TokensInfo;
