import React from 'react';

const TermsOfService = () => {
    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold text-center mb-8">Terms of Service</h1>
                <div className="bg-white p-8 rounded-lg shadow-lg">
                    <p className="mb-4">
                        Welcome to iAssets. The following terms of service ("Terms") govern your access to and use of our decentralized social media platform ("Platform"), iAssets, iBird, iAssets.org, iBird.io our websites, applications, services, and features thereof (collectively, the "Services").
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Acceptance of Terms</h2>
                    <p className="mb-4">
                        By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use our Services.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Eligibility</h2>
                    <p className="mb-4">
                        You must be at least 18 years old to access or use the Services. By agreeing to these Terms, you represent and warrant that you are of legal age to form a binding contract and meet all of the eligibility requirements in these Terms.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">User Content and Conduct</h2>
                    <p className="mb-4">
                        All content published on the Platform is the sole responsibility of the person who originated such content. You understand that all information, data, text, messages, or other materials posted or displayed (hereinafter, "Content") are the sole responsibility of the person from which such content originated.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">User Rights</h2>
                    <p className="mb-4">
                        The User is granted a non-exclusive, non-transferable, and revocable license to use the Platform, including all content posted or uploaded by the User (the “Content”). The User retains 100% of the rights to the Content posted or uploaded to the Platform. Users of the Platform may not reproduce, modify, or distribute any Content without the express written consent of the User outside of our platform.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">User Obligations</h2>
                    <p className="mb-4">
                        The User agrees to use the Platform in accordance with all applicable local, state, and federal laws and regulations. The User agrees not to upload or post any Content that is obscene, pornographic, defamatory, abusive, or otherwise unlawful. The User is solely responsible for all Content posted or uploaded to the Platform by the User.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Liability for Content</h2>
                    <p className="mb-4">
                        ibird does not control the Content posted via the Services and does not guarantee the accuracy, integrity, or quality of such Content. You understand that by using the Services, you may be exposed to Content that is offensive, harmful, inaccurate, or otherwise inappropriate.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Decentralization and Data Storage</h2>
                    <p className="mb-4">
                        All data and content on ibird are stored on the Hedera Hashgraph consensus service and Arweave. All data and content are public and can be accessed by anyone. We do not take responsibility for the security, privacy, or integrity of the data or content that is stored on the Hedera Hashgraph consensus service or Arweave.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Cryptocurrency Wallets</h2>
                    <p className="mb-4">
                        Users are responsible for managing and securing their cryptocurrency wallets. ibird.io is not responsible for any loss or damage related to the use or misuse of users' cryptocurrency wallets.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">ASSET Token</h2>
                    <p className="mb-4">
                        Users are solely responsible for all risks associated with the use, holding, or investment in the ASSET Token. iAssets makes no guarantees regarding the ASSET Token, including its functionality, usability, or value.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
                    <p className="mb-4">
                        In no event will ibird be liable for any direct, indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Services; (ii) any conduct or content of any third party on the Services; (iii) any content obtained from the Services; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Dispute Resolution</h2>
                    <p className="mb-4">
                        Any disputes relating to these Terms or the Services will be resolved through final and binding arbitration, rather than in court.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
                    <p className="mb-4">
                        ibird reserves the right to amend these Terms at any time without prior notice. The determination of what is considered a significant modification will be at our sole discretion.
                    </p>
                    <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
                    <h3 className="text-xl font-semibold mb-2">Information Collection</h3>
                    <p className="mb-4">
                        When you use ibird, all data you provide is public and stored on the Hedera Hashgraph Consensus Service and Arweave. This includes, but is not limited to, posts, likes, comments, and follows.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Information Use</h3>
                    <p className="mb-4">
                        We do not use or share your information with anyone except as described in this Privacy Policy.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Public Nature of Information</h3>
                    <p className="mb-4">
                        Please be aware that any information you provide on ibird is publicly available and can be seen by anyone on the platform and Hedera Hashgraph.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Wallet Information</h3>
                    <p className="mb-4">
                        You are responsible for maintaining the confidentiality of your wallet and its contents. We will not be responsible for any loss or damage resulting from your failure to properly secure your wallet.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Changes to Privacy Policy</h3>
                    <p className="mb-4">
                        We may modify this Privacy Policy at any time, and changes will become effective upon posting.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">User Content and Conduct</h3>
                    <p className="mb-4">
                        If you reside in a jurisdiction that enforces the "Right to Erasure" or similar data erasure rules, you are not permitted to use the platform. By accessing or using our Services, you confirm that you do not reside in such a jurisdiction or, if you do, you will not use the platform due to the conflict between these terms and the data erasure rules in your area.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;